/* Loader.css */

.loader {
    border-top-color: transparent; /* Make the top border transparent */
    border-radius: 50%;
    border-style: solid;
    border-width: 8px;
    width: 80px; /* Adjust as needed */
    height: 80px; /* Adjust as needed */
    animation: spin 1s linear infinite; /* Apply custom spinning animation */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  