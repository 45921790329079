@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  scroll-behavior: smooth;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: #F6F6F5;
  scroll-behavior: smooth;
}

input,
select {
  outline: none;
}

.batch_heart {
  background: tomato;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: -24%;
  top: -9%;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Styling for the scrollbar */
::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
  height: 12px;
  /* Height of the scrollbar (for horizontal scroll) */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Background color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: #29625d;
  /* Color of the scrollbar itself */
  border-radius: 10px;
  /* Optional: rounding the corners of the thumb */
}

/* For Firefox (scrollbar-width and scrollbar-color are supported) */
html {
  scrollbar-width: thin;
  /* Thin scrollbar */
  scrollbar-color: #29625d #f1f1f1;
  /* Thumb color and track color */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* Internet Explorer and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.ql-editor {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  scroll-behavior: smooth;
  font-size: 16px;
  /* Set your desired default font size */
}

.ql-editor h1 {
  font-size: 24px;
  /* Size for header 1 */
}

.ql-editor h2 {
  font-size: 20px;
  /* Size for header 2 */
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 5px;
  padding: 0;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  border: 1px solid rgb(201, 201, 201);
}


/*Chrome thumb*/

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 5px;
  /*16x16px adjusted to be same as 14x14px on moz*/
  height: 16px;
  width: 16px;
  border-radius: 5px;
  background: #29625d;
  border: 1px solid #29625d;
}


/*Mozilla thumb*/

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  -moz-border-radius: 5px;
  height: 14px;
  width: 14px;
  border-radius: 5px;
  background: #29625d;
  border: 1px solid #29625d;
}


/*IE & Edge input*/

input[type=range]::-ms-track {
  width: 100%;
  height: 6px;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: #c5c5c5;
  border-width: 2px 0;
  /*remove default tick marks*/
  color: #29625d;
}


/*IE & Edge thumb*/

input[type=range]::-ms-thumb {
  height: 14px;
  width: 14px;
  border-radius: 5px;
  background: #29625d;
  border: 1px solid #29625d;
}


/*IE & Edge left side*/

input[type=range]::-ms-fill-lower {
  background: #919e4b;
  border-radius: 2px;
}


/*IE & Edge right side*/

input[type=range]::-ms-fill-upper {
  background: #c5c5c5;
  border-radius: 2px;
}


/*IE disable tooltip*/

input[type=range]::-ms-tooltip {
  display: none;
}

input[type="text"] {
  border: block;
}

/* Custom Checkbox Styles */
input[type="checkbox"] {
  -webkit-appearance: none;
  /* Removes default styling */
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
  /* Default border */
  border-radius: 4px;
  /* Rounded corners */
  background-color: transparent;
  /* Background is transparent to inherit page background */
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  /* Smooth transition for background and border */
}

/* Light mode border */
input[type="checkbox"]:not(:checked) {
  border-color: #d1d1d1;
  /* Light border */
}

/* Dark mode border */
input[type="checkbox"]:not(:checked) {
  border-color: #555;
  /* Darker border in dark mode */
}

/* When checked, change the background and border */
input[type="checkbox"]:checked {
  background-color: #29625d;
  /* Custom background when checked */
  border-color: #29625d;
  /* Border changes to greenish */
}

/* Change the checkbox border on focus (optional) */
input[type="checkbox"]:focus {
  border-color: #3b82f6;
  /* Focused border in blue */
  outline: none;
  /* Removes default outline */
}

/* Optionally, add a checkmark (using ::before) */
input[type="checkbox"]:checked::before {
  content: '✔';
  /* Checkmark content */
  position: absolute;
  top: -2px;
  bottom: 2px;
  left: 3px;
  font-size: 13px;
  color: white;
}

/* Dark Mode Adjustments */
@media (prefers-color-scheme: dark) {
  input[type="checkbox"]:not(:checked) {
    border-color: #888;
    /* Darker border color */
  }

  input[type="checkbox"]:checked {
    background-color: #29625d;
    /* Green background when checked in dark mode */
    border-color: #29625d;
    /* Same green border */
  }
}

.slick-prev,
.slick-next {
  display: none !important;
}

.clip-path-trapezium {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%);
}

/* Global Theme Variables */
:root {
  --toast-bg-light: #29625d;
  /* Light mode background color */
  --toast-bg-dark: #2c3e50;
  /* Dark mode background color */
  --toast-text-light: #fff;
  /* Light mode text color */
  --toast-text-dark: #ecf0f1;
  /* Dark mode text color */
  --toast-border-radius: 8px;
  /* Border radius for toasts */
  --toast-font-family: 'Arial', sans-serif;
  --toast-font-weight: 400;
  --toast-font-size: 16px;
  --toast-progress-color: #f1c40f;
  /* Progress bar color */
}

/* Light mode */
@media (prefers-color-scheme: light) {
  .custom-toast-container .Toastify__toast {
    background-color: var(--toast-bg-light) !important;
    color: var(--toast-text-light) !important;
  }

  .custom-toast-container .Toastify__toast--success {
    background-color: #4caf50 !important;
    /* Success toast background */
  }

  .custom-toast-container .Toastify__toast--error {
    background-color: #520e09 !important;
    /* Error toast background */
  }

  .custom-toast-container .Toastify__toast--info {
    background-color: #2a4458 !important;
    /* Info toast background */
  }

  .custom-toast-container .Toastify__toast--warning {
    background-color: #ff9800 !important;
    /* Warning toast background */
  }

  .custom-toast-container .Toastify__toast-body {
    font-size: var(--toast-font-size) !important;
  }

  .custom-toast-container .Toastify__progress-bar {
    background-color: var(--toast-progress-color);
    /* Progress bar color */
  }

  .custom-toast-container .Toastify__close-button {
    color: var(--toast-text-light) !important;
    font-size: 18px;
    font-weight: bold;
  }
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  .custom-toast-container .Toastify__toast {
    background-color: var(--toast-bg-dark) !important;
    color: var(--toast-text-dark) !important;
  }

  .custom-toast-container .Toastify__toast--success {
    background-color: #105c12 !important;
    /* Success toast background */
  }

  .custom-toast-container .Toastify__toast--error {
    background-color: #550b05 !important;
    /* Error toast background */
  }

  .custom-toast-container .Toastify__toast--info {
    background-color: #2d4d68 !important;
    /* Info toast background */
  }

  .custom-toast-container .Toastify__toast--warning {
    background-color: #ff9800 !important;
    /* Warning toast background */
  }

  .custom-toast-container .Toastify__toast-body {
    font-size: var(--toast-font-size) !important;
  }

  .custom-toast-container .Toastify__progress-bar {
    background-color: var(--toast-progress-color);
    /* Progress bar color */
  }

  .custom-toast-container .Toastify__close-button {
    color: var(--toast-text-dark) !important;
    font-size: 18px;
    font-weight: bold;
  }

  /* Make the toast message 60% width on small screens */
  @media (max-width: 600px) {
    .custom-toast-container .Toastify__toast {
      width: 60% !important;
      /* Adjust the width to 60% of the screen */
      margin: 0 auto;
      /* Center the toast horizontally */
      border-radius: 12px;
    }
  }
}